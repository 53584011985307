import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const TambahProject = () => {
    const [judul, setJudul] = useState('');
    const [deskripsi, setDeskripsi] = useState('');
    const [foto, setFoto] = useState(null);
    const [jurusan, setJurusan] = useState('');
    const [kelas, setKelas] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validasi input
        if (!judul || !deskripsi || !foto || !jurusan || !kelas) {
            setError('Semua kolom harus diisi!');
            return;
        }

        const formData = new FormData();
        formData.append('judul', judul);
        formData.append('deskripsi', deskripsi);
        formData.append('foto', foto);
        formData.append('jurusan', jurusan);
        formData.append('kelas', kelas);

        const token = localStorage.getItem('authToken');

        try {
            const response = await fetch('https://api-karyasiswa.wokaproject.id/api/v1/project/tambah', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            const result = await response.json();

            if (response.ok) {
                // Berhasil mengunggah project
                navigate('/beranda'); // Arahkan ke halaman beranda setelah berhasil menambah project
            } else {
                setError(result.message || 'Gagal menambah project');
            }
        } catch (err) {
            setError('Terjadi kesalahan saat mengunggah data');
        }
    };

    const handleFileChange = (e) => {
        setFoto(e.target.files[0]);
    };

    return (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center">
            <div className="bg-white p-8 rounded-md shadow-md w-full max-w-lg">
                <h2 className="text-2xl font-bold mb-6 text-gray-700">Tambah Project</h2>
                
                {error && (
                    <div className="bg-red-100 text-red-800 border border-red-300 rounded-md p-4 mb-4">
                        {error}
                    </div>
                )}

                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="judul" className="block text-gray-700">Judul Project</label>
                        <input
                            type="text"
                            id="judul"
                            className="w-full px-4 py-2 border rounded-md"
                            value={judul}
                            onChange={(e) => setJudul(e.target.value)}
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="deskripsi" className="block text-gray-700">Deskripsi</label>
                        <textarea
                            id="deskripsi"
                            className="w-full px-4 py-2 border rounded-md"
                            value={deskripsi}
                            onChange={(e) => setDeskripsi(e.target.value)}
                        ></textarea>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="foto" className="block text-gray-700">Foto</label>
                        <input
                            type="file"
                            id="foto"
                            className="w-full"
                            onChange={handleFileChange}
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="jurusan" className="block text-gray-700">Jurusan</label>
                        <select
                            id="jurusan"
                            className="w-full px-4 py-2 border rounded-md"
                            value={jurusan}
                            onChange={(e) => setJurusan(e.target.value)}
                        >
                            <option value="">Pilih Jurusan</option>
                            <option value="RPL">Rekayasa Perangkat Lunak</option>
                            <option value="TKJ">Teknik Komputer dan Jaringan</option>
                        </select>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="kelas" className="block text-gray-700">Kelas</label>
                        <select
                            id="kelas"
                            className="w-full px-4 py-2 border rounded-md"
                            value={kelas}
                            onChange={(e) => setKelas(e.target.value)}
                        >
                            <option value="">Pilih Kelas</option>
                            <option value="X RPL A">X RPL A</option>
                            <option value="X RPL B">X RPL B</option>
                            <option value="XI RPL A">XI RPL A</option>
                            <option value="XI RPL B">XI RPL B</option>
                            <option value="XII RPL A">XII RPL A</option>
                            <option value="XII RPL B">XII RPL B</option>
                        </select>
                    </div>

                    <button
                        type="submit"
                        className="bg-blue-600 text-white py-2 px-4 rounded-md shadow hover:bg-blue-700 transition w-full"
                    >
                        Tambah Project
                    </button>
                </form>
            </div>
        </div>
    );
};

export default TambahProject;
